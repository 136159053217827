import * as React from 'react';
import { Question } from '../../../types';
import Text from '../../shared/Text';

interface Props {
  onChange: (_: string, ___: string, __: number | string) => void;
  question: Question;
  value: number;
}

class Range extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { onChange, question, value } = this.props;

    return (
      <>
        <Text h6>{question['attributes'].title}</Text>
        {question['attributes'].description ? (
          <p className={`my-3`}>{question['attributes'].description}</p>
        ) : (
          ''
        )}
        <>
          <input
            className={`w-100`}
            type={'range'}
            name={question['id']}
            onChange={e => onChange(question['id'], 'range', e.target.value)}
            value={value}
            min={question['attributes'].startWith}
            max={question['attributes'].endWith}
          />
          <div className={`w-100 d-flex justify-content-between`}>
            <p>{question['attributes'].startWith}</p>
            <p>{question['attributes'].endWith}</p>
          </div>
          <div className={`w-100 d-flex justify-content-center`}>
            <p>{question['attributes'].unit}</p>
          </div>
        </>
      </>
    );
  }
}

export default Range;
