import * as React from 'react';
import * as s from './YesNo.module.scss';
import { Question } from '../../../types';

interface Props {
  onChange: (_: string, __: Boolean) => void;
  question: Question;
}

class YesNo extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { onChange, question } = this.props;

    return (
      <div className={`${s.yesNoWrapper}`}>
        <p className={s.title}>{question['attributes'].title}</p>
        <>
          <div className={s.question}>
            <div className="d-inline-flex align-items-center">
              <label className={s.alternative}>
                <input
                  onChange={() => onChange(question['id'], true)}
                  type={'radio'}
                  name={question['attributes'].title}
                />
                <span className={s.radio}></span>
              </label>
              <p>Yes</p>
            </div>
          </div>
          <div className={s.question}>
            <div className="d-inline-flex align-items-center">
              <label className={s.alternative}>
                <input
                  onChange={() => onChange(question['id'], false)}
                  type={'radio'}
                  name={question['attributes'].title}
                  defaultChecked={true}
                />
                <span className={`${s.radio}`}></span>
              </label>
              <p>No</p>
            </div>
          </div>
        </>
      </div>
    );
  }
}

export default YesNo;
