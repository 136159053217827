import * as React from 'react';
import * as s from './TextQuestion.module.scss';
import { Question } from '../../../types';
import Text from '../../shared/Text';

interface Props {
  onChange: (_: string, ___: string, __: number | string) => void;
  question: Question;
  value: string;
}

class TextQuestion extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { onChange, question, value } = this.props;

    const InputClasses = [s.input];

    let input;
    if (question['attributes'].singleLine) {
      input = (
        <input
          className={`${InputClasses.join(' ')} w-100`}
          type="text"
          name={question['attributes'].id}
          onChange={e => onChange(question['id'], 'text', e.target.value)}
          value={value}
        />
      );
    } else {
      input = (
        <textarea
          className={`col-12 ${s.textarea} ${value.length > 0 ? s.focus : ''}`}
          onChange={e => onChange(question['id'], 'text', e.target.value)}
          value={value}
          rows={8}
          maxLength={5000}
        />
      );
    }

    return (
      <div className={`${s.wrapper} ${value.length > 0 ? s.focus : ''}`}>
        <Text h6>{question['attributes'].title}</Text>
        {question['attributes'].description ? (
          <p className={`my-3`}>{question['attributes'].description}</p>
        ) : (
          ''
        )}
        {input}
      </div>
    );
  }
}

export default TextQuestion;
