import * as React from 'react';
import * as s from './Checkbox.module.scss';

interface Props {
  isChecked: boolean;
  onChange: (_: boolean) => any;
  checkBoxText: string;
}

class Checkbox extends React.Component<Props> {
  render() {
    const { onChange, isChecked, checkBoxText } = this.props;
    return (
      <>
        <label className={s.container}>
          <input
            type="checkbox"
            onChange={e => onChange(e.target.checked)}
            checked={isChecked}
          />
          <span className={s.checkmark} />
        </label>
        <div className={s.checkBoxText}>
          {'I have read the'}{' '}
          <a target="_blank" href={`/privacy-policy/`}>
            {'Privacy Policy'}
          </a>{' '}
          {
            'and confirm that Zound Industries International AB store my personal details to be able to contact me for job opportunities.'
          }
        </div>
      </>
    );
  }
}
export default Checkbox;
