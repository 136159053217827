// extracted by mini-css-extract-plugin
export var body = "ApplyForm-module--body--TJ-tV";
export var buttonBottom = "ApplyForm-module--buttonBottom--Xlevj";
export var checkbox = "ApplyForm-module--checkbox---NPDX";
export var errortext = "ApplyForm-module--errortext--Cg-D+";
export var focus = "ApplyForm-module--focus--Yd2Bl";
export var form = "ApplyForm-module--form--S-b+t";
export var hide = "ApplyForm-module--hide--NiCTJ";
export var horizontalTextBlock = "ApplyForm-module--horizontalTextBlock--3SCTu";
export var infoText = "ApplyForm-module--infoText--Hb79D";
export var input = "ApplyForm-module--input--L-TXK";
export var inputPN = "ApplyForm-module--inputPN--eq77K";
export var inputPNAnimationText = "ApplyForm-module--inputPNAnimationText--DJuh2";
export var inputPNWrapper = "ApplyForm-module--inputPNWrapper--6UDbI";
export var placeholder = "ApplyForm-module--placeholder--S9QH0";
export var selectedFlag = "ApplyForm-module--selected-flag--f2I-m";
export var show = "ApplyForm-module--show--xa6Je";
export var successtext = "ApplyForm-module--successtext--t7tjQ";
export var textarea = "ApplyForm-module--textarea--PdDIG";
export var textcount = "ApplyForm-module--textcount--ShQKc";