import React from 'react';
import { graphql } from 'gatsby';
import Section from '../components/shared/Section';
import Text from '../components/shared/Text';
import * as s from './position.module.scss';
import Layout from '../components/Layout';
import pin from '../../static/pin.svg';
import ApplyForm from '../components/shared/ApplyForm';
import Footer from '../components/Footer';
import Hero from '../components/shared/Hero';
import Cookie from '../components/shared/Cookie';
import { SEO } from '../types';
import ShareGroup from '../components/shared/ShareGroup';
import Script from 'react-load-script';
import Button from '~components/swace/button';
import linkedinSvg from '../img/linkedin_white.svg';
import { Waypoint } from 'react-waypoint';

class Position extends React.Component<any> {
  applyFormRef = null;

  state = {
    applyAnchorVisible: false,
  };

  handleScriptError() {
    console.error('Error occurred when creating script tag');
  }

  handleScriptLoad(jobID) {
    window.adway.event.viewJob(null, jobID);
  }

  scrollToApply = () => window.scrollTo(0, this.applyFormRef.offsetTop);

  render() {
    const { job, wp } = this.props.data;
    const { checkboxText, buttonText, text, title } =
      wp.themeGeneralSettings.applyFormSettings;

    const jobLink = `${process.env.GATSBY_FRONTEND_BASE_URL}/positions/${job.links.relativeUrl}`;
    // Mock yoast meta for positions
    const seo: SEO = {
      title: job.attributes.title,
      metadesc: text,
      canonical: jobLink,
      ogimage: {
        source_url: job.attributes.picture.standard,
      },
    };

    const jobID = job && parseInt(job.id, 10);
    const isExternalApplication =
      job && job.attributes && job.attributes.externalApplicationUrl !== '';

    const applyWithLinkedinUrl = `https://zound.teamtailor.com/jobs/${job.links.relativeUrl}/applications/new`;

    return (
      <>
        <Script
          url="https://analytics.adway.ai/script/zound.js"
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this, jobID)}
        />
        <Layout disableBackground seo={seo} siteMeta={wp.generalSettings}>
          <Section slim wide sectionName="position background">
            <Hero backgroundImage={{ url: job.attributes.picture.standard }} />
          </Section>
          <ShareGroup link={jobLink} text={job.attributes.title} desktop />
          <Section slim sectionName="position">
            <div className="row">
              <div className="col-md-10 col-xl-8 offset-md-1 offset-xl-2">
                <div className={`${s.location} d-flex align-items-center`}>
                  <img src={pin} />
                  <Text h5>{job.relationships.location.attributes.city}</Text>
                </div>
              </div>
            </div>
            <div className={`${s.title} row`}>
              <div className="col-md-10 col-xl-8 offset-md-1 offset-xl-2">
                <Text h3>{job.attributes.title}</Text>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-5 col-xl-4 offset-md-1 offset-xl-2 order-md-1">
                <div className={s.applyInfoText}>APPLY MANUALLY</div>
              </div>
              <div className="col-md-5 col-xl-4 offset-md-1 offset-xl-2 order-md-3">
                {isExternalApplication ? (
                  <Button
                    filled
                    notFixedWidth
                    p0
                    className={s.specialButton}
                    href={job.attributes.externalApplicationUrl}
                    isExternalUrl
                  >
                    Apply now
                  </Button>
                ) : (
                  <Button
                    filled
                    notFixedWidth
                    p0
                    className={s.specialButton}
                    onClick={this.scrollToApply}
                  >
                    Apply now
                  </Button>
                )}
              </div>
              <div className="col-md-5 col-xl-4 order-md-2">
                {!isExternalApplication && (
                  <div className={s.applyInfoText}>
                    APPLY USING YOUR SOCIAL MEDIA ACCOUNT
                  </div>
                )}
              </div>
              <div className="col-md-5 col-xl-4 order-md-4">
                {!isExternalApplication && (
                  <Button
                    filled
                    notFixedWidth
                    p0
                    className={s.specialButton}
                    isExternalUrl
                    href={applyWithLinkedinUrl}
                  >
                    Apply with Social Media
                  </Button>
                )}
              </div>
            </div>
            <div className={`${s.subtitle} row`}>
              <div className="col-md-10 col-xl-8 offset-md-1 offset-xl-2">
                <Text h5>About the position</Text>
              </div>
            </div>
            <button
              className={`${s.anchorButton} ${
                this.state.applyAnchorVisible ? s.mount : s.unmount
              }`}
              onClick={this.scrollToApply}
            >
              <span className={s.anchorButtonContent}>APPLY FOR THIS JOB</span>
            </button>
            <Waypoint
              onEnter={() => {
                this.setState({ applyAnchorVisible: true });
              }}
              onLeave={() => {
                this.setState({ applyAnchorVisible: false });
              }}
              topOffset={200}
              bottomOffset={300}
            >
              <div>
                <div className="row">
                  <div
                    className={`col-md-10 col-xl-8 offset-md-1 offset-xl-2 ${s.wysiwygWrapper}`}
                  >
                    <div
                      className={s.body}
                      dangerouslySetInnerHTML={{ __html: job.attributes.body }}
                    />
                  </div>
                </div>
                <ShareGroup
                  link={jobLink}
                  text={job.attributes.title}
                  desktop={false}
                />
              </div>
            </Waypoint>
          </Section>
          <div
            ref={(ref) => {
              this.applyFormRef = ref;
            }}
          />
          {!isExternalApplication && (
            <ApplyForm
              jobId={job.id}
              backendUrl={wp.generalSettings.url}
              checkBoxText={checkboxText}
              buttonText={buttonText}
              title={title}
              text={text}
              questions={job.relationships.questions}
              resumeRequirement={job.attributes.resumeRequirement}
            />
          )}
          <Footer />
        </Layout>
      </>
    );
  }
}

export const pageQuery = graphql`
  query PositionsById($id: String!) {
    job(id: { eq: $id }) {
      id
      attributes {
        title
        body
        resumeRequirement
        picture {
          standard
        }
        externalApplicationUrl
      }
      links {
        relativeUrl
      }
      relationships {
        location {
          attributes {
            city
          }
        }
        questions {
          id
          type
          attributes {
            title
            multiple
            alternatives {
              id
              title
            }
            startWith
            endWith
            unit
            singleLine
            description
          }
        }
      }
    }
    wp {
      themeGeneralSettings {
        applyFormSettings {
          buttonText
          title
          text
          checkboxText
        }
      }
      generalSettings {
        title
        url
        description
      }
    }
  }
`;

export default Position;
