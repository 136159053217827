import * as React from 'react';
import * as s from './ApplyForm.module.scss';
import InputFile from './InputFile';
import Button from '../../swace/button';
import axios from 'axios';
import InputField from './InputField';
import Checkbox from './Checkbox';
import Section from '../Section';
import Text from '../../shared/Text';
import Script from 'react-load-script';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import MultipleChoice from './MultipleChoice';
import YesNo from './YesNo';
import TextQuestion from './TextQuestion';
import Range from './Range';
import { Question, Answer } from '../../../types';

interface Props {
  jobId: number;
  backendUrl: string;
  checkBoxText: string;
  buttonText: string;
  title: string;
  text: string;
  questions: Array<Question>;
  resumeRequirement: 'optional' | 'required' | string;
}

interface State {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  file: File;
  otherFile: File;
  message: string;
  gdprCheckbox: boolean;
  isTextAreaFocused: boolean;
  errorText: string;
  successText: string;
  applicationStarted: boolean;
  phoneNumberFocus: boolean;
  answers: Answer | {};
}

class ApplyForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let questions = this.props.questions;
    let answers = {};

    Object.keys(questions).forEach((key) => {
      let question = this.props.questions[key];
      switch (question.type) {
        case 'Choice':
          answers[question.id] = {
            type: 'choice',
            answers: {},
            allowMultiple: question.attributes.multiple,
          };
          question.attributes.alternatives.forEach(
            (alternative) =>
              (answers[question.id].answers[alternative.id] = false)
          );
          break;
        case 'Boolean':
          answers[question.id] = {
            type: 'boolean',
            answers: {
              '1': false,
            },
            allowMultiple: false,
          };
          break;
        case 'Range':
          answers[question.id] = {
            min: question.attributes.startWith,
            max: question.attributes.endWith,
            type: 'range',
            range: question.attributes.startWith,
          };
          break;
        case 'Text':
          answers[question.id] = {
            type: 'text',
            text: '',
          };
          break;
        default:
          break;
      }
    });

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      file: null,
      otherFile: null,
      message: '',
      gdprCheckbox: false,
      isTextAreaFocused: false,
      errorText: '',
      successText: '',
      applicationStarted: false,
      phoneNumberFocus: false,
      answers: answers,
    };
  }

  setPhoneNumber = (valid, phoneNumber, country) => {
    if (valid && phoneNumber && country.dialCode) {
      const formattedPhoneNumber = '+' + country.dialCode + phoneNumber;
      this.setState({ phoneNumber: formattedPhoneNumber });
    }
  };

  handleScriptError() {
    console.error('Error occurred when creating script tag');
  }

  handleScriptLoad() {
    // console.log('Script finished loading in ApplyForm.tsx');
  }

  fileChange = (files: FileList) => {
    if (files && files.length > 0) {
      this.setState({ file: files[0] });
    }
  };

  otherFileChange = (files: FileList) => {
    if (files && files.length > 0) {
      this.setState({ otherFile: files[0] });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { backendUrl, jobId } = this.props;
    const { file, otherFile, gdprCheckbox, phoneNumber, answers } = this.state;
    const formData = new FormData(e.target);
    formData.append('phone_number', phoneNumber);
    formData.append('file', file);
    formData.append('other_file', otherFile);
    formData.append('job_id', jobId.toString());
    answers && formData.append('answers', JSON.stringify(answers));
    this.setState({ errorText: '' });
    this.setState({ successText: '' });

    if (!gdprCheckbox) {
      this.setState({ errorText: 'Terms need to be accepted' });
      return;
    }

    axios
      .post(backendUrl + '/wp-json/teamtailor/v1/job_applications', formData)
      .then((response) => {
        this.setState({ successText: response.data });
        window.adway.event.applyCompleted();
      })
      .catch((error) => {
        this.setState({ errorText: error.response.data });
      });
  };

  startApplication = () => {
    const applicationStarted = this.state.applicationStarted;
    if (!applicationStarted) {
      this.setState({ applicationStarted: true });
      window.adway.event.applyStarted();
    }
  };

  updateChoice = (questionId, alternativeid) => {
    let stateAnswers = this.state.answers;
    let answeredQustion = stateAnswers[questionId];

    const allowMultipleAnswers =
      answeredQustion.allowMultiple ||
      Object.keys(answeredQustion.answers).length === 1;

    if (!allowMultipleAnswers) {
      Object.keys(answeredQustion.answers).forEach(
        (key) => (answeredQustion.answers[key] = false)
      );
    }
    answeredQustion.answers[alternativeid] =
      !answeredQustion.answers[alternativeid];
    this.setState({ answers: stateAnswers });
  };

  toggleYesNo = (questionId, answer) => {
    let stateAnswers = this.state.answers;
    let answeredQuestion = stateAnswers[questionId];

    answeredQuestion.answers['1'] = answer;

    this.setState({ answers: stateAnswers });
  };

  updateAnswer = (questionId, type, answer) => {
    let stateAnswers = this.state.answers;
    let answeredQuestion = stateAnswers[questionId];

    answeredQuestion[type] = answer;

    this.setState({ answers: stateAnswers });
  };

  render() {
    const {
      checkBoxText,
      buttonText,
      title,
      text,
      resumeRequirement,
      questions,
    } = this.props;
    const {
      firstName,
      lastName,
      email,
      file,
      otherFile,
      message,
      gdprCheckbox,
      isTextAreaFocused,
      errorText,
      successText,
      phoneNumberFocus,
      answers,
    } = this.state;
    const formWrapperClasses = [];
    const formClasses = [s.form];

    const animationTextClasses = [s.inputPNAnimationText];

    if (phoneNumberFocus) {
      animationTextClasses.push(s.show);
    }

    const questionElements = Object.keys(questions).map((questionKey) => {
      switch (questions[questionKey].type) {
        case 'Choice':
          return (
            <div className="col-12 my-5" key={questions[questionKey].id}>
              <MultipleChoice
                onChange={this.updateChoice}
                question={questions[questionKey]}
              />
            </div>
          );
        case 'Boolean':
          return (
            <div className="col-12 my-5" key={questions[questionKey].id}>
              <YesNo
                onChange={this.toggleYesNo}
                question={questions[questionKey]}
              />
            </div>
          );
        case 'Text':
          return (
            <div className="col-12 my-5" key={questions[questionKey].id}>
              <TextQuestion
                onChange={this.updateAnswer}
                question={questions[questionKey]}
                value={answers[questions[questionKey].id].text}
              />
            </div>
          );
        case 'Range':
          return (
            <div className="col-12 my-5" key={questions[questionKey].id}>
              <Range
                onChange={this.updateAnswer}
                question={questions[questionKey]}
                value={answers[questions[questionKey].id].range}
              />
            </div>
          );
        default:
          break;
      }
    });

    successText !== '' && formWrapperClasses.push(s.hide);
    return (
      <Section sectionName="ApplyForm">
        <Script
          url="https://analytics.adway.ai/script/zound.js"
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        />
        <div className={formWrapperClasses.join(' ')}>
          <div className="row">
            <div className="col-md-10 col-xl-8 offset-md-1 offset-xl-2">
              <Text h3>{title}</Text>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-xl-8 offset-md-1 offset-xl-2">
              <div className={s.body}>
                <p>{text}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10 col-xl-8 offset-md-1 offset-xl-2">
              <form
                className={formClasses.join(' ')}
                onSubmit={this.onSubmit}
                onChange={this.startApplication}
              >
                <div className={`row`}>
                  <InputField
                    type="text"
                    name="first_name"
                    label="First name"
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    value={firstName}
                    required
                  />
                  <InputField
                    type="text"
                    name="last_name"
                    label="Last name"
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    value={lastName}
                    required
                  />
                  <InputField
                    type="email"
                    name="email"
                    label="Email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    value={email}
                    required
                  />
                  <div className="col-12 col-md-6">
                    <div
                      onFocus={() => this.setState({ phoneNumberFocus: true })}
                      onBlur={() => this.setState({ phoneNumberFocus: false })}
                    >
                      <div className={s.horizontalTextBlock}>
                        <Text h6>Phone</Text>
                        <div className={s.infoText}>optional</div>
                      </div>
                      <IntlTelInput
                        onPhoneNumberChange={(
                          valid: boolean,
                          phone: any,
                          country: any
                        ) => this.setPhoneNumber(valid, phone, country)}
                        onSelectFlag={(
                          valid: boolean,
                          phone: any,
                          country: any
                        ) => this.setPhoneNumber(valid, phone, country)}
                        customPlaceholder={() =>
                          phoneNumberFocus ? '' : '  XXX XXX XXX'
                        }
                        containerClassName={`intl-tel-input ${s.inputPNWrapper}`}
                        inputClassName={s.inputPN}
                        defaultCountry="se"
                        separateDialCode
                        preferredCountries={['se', 'de', 'fi', 'gb', 'no']}
                      />
                    </div>
                  </div>

                  <InputFile
                    onChange={this.fileChange}
                    file={file}
                    title="Upload CV"
                    inputId="file"
                    optional={resumeRequirement === 'optional'}
                  />
                  <InputFile
                    onChange={this.otherFileChange}
                    file={otherFile}
                    title="Upload other files"
                    inputId="other-file"
                    optional
                  />
                  <div className="col-12">
                    <div className={s.horizontalTextBlock}>
                      <Text h6>
                        Tell us about yourself and why you want to work at Zound
                      </Text>
                      <div className={s.infoText}>optional</div>
                    </div>
                    <textarea
                      className={`col-12 ${s.input} ${s.textarea} ${
                        isTextAreaFocused || message.length > 0 ? s.focus : ''
                      }`}
                      name="message"
                      onChange={(e) =>
                        this.setState({ message: e.target.value })
                      }
                      value={message}
                      onFocus={() => this.setState({ isTextAreaFocused: true })}
                      onBlur={() => this.setState({ isTextAreaFocused: false })}
                      maxLength={5000}
                    />
                    <div className={s.textcount}>{message.length}/5000</div>
                  </div>
                  {questionElements && (
                    <div className="col-12 px-0">{questionElements}</div>
                  )}
                  <div className={`col-12 d-flex ${s.checkbox}`}>
                    <Checkbox
                      checkBoxText={checkBoxText}
                      isChecked={gdprCheckbox}
                      onChange={() =>
                        this.setState({ gdprCheckbox: !gdprCheckbox })
                      }
                    />
                  </div>
                  <div className="col-12 d-flex justify-content-center">
                    <div className={s.errortext} id="errortext">
                      {errorText}
                    </div>
                  </div>
                  <Button
                    className={s.buttonBottom}
                    notFixedWidth
                    filled
                    onClick={() => {}}
                    type="submit"
                  >
                    <div>{buttonText}</div>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={s.successtext} id="successtext">
          {successText}
        </div>
      </Section>
    );
  }
}

export default ApplyForm;
