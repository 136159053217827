import * as React from 'react';
import * as s from './MultipleChoice.module.scss';
import { Question } from '../../../types';

interface Props {
  onChange: (_: string, __: string) => void;
  question: Question;
}

class MultipleChoice extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { onChange, question } = this.props;

    const isCheckbox =
      question['attributes'].multiple ||
      Object.entries(question['attributes'].alternatives).length === 1;
    const hasAlternatives =
      question && question['attributes'] && question['attributes'].alternatives;
    const alternatives =
      hasAlternatives &&
      Object.keys(question['attributes'].alternatives).map((key) => {
        let alternative = question['attributes'].alternatives[key];
        return (
          <div className={s.questionWrapper} key={alternative.id}>
            <div className="d-inline-flex align-items-center">
              <label
                className={isCheckbox ? `${s.multiple}` : `${s.radioButton}`}
              >
                <input
                  onChange={() => onChange(question['id'], alternative.id)}
                  type={isCheckbox ? 'checkbox' : 'radio'}
                  name={!isCheckbox ? question['attributes'].title : ''}
                />
                <span
                  className={isCheckbox ? `${s.slider}` : `${s.radio}`}
                ></span>
              </label>
              <p>{alternative.title}</p>
            </div>
          </div>
        );
      });

    return (
      <div className={`${s.wrapper}`}>
        <p className={s.title}>{question['attributes'].title}</p>
        {alternatives}
      </div>
    );
  }
}

export default MultipleChoice;
