// extracted by mini-css-extract-plugin
export var animationText = "InputFile-module--animationText--xQdzy";
export var buttonwrapper = "InputFile-module--buttonwrapper--hxgyB";
export var closeIcon = "InputFile-module--closeIcon--cvtsF";
export var fileAdded = "InputFile-module--fileAdded--81pih";
export var horizontalTextBlock = "InputFile-module--horizontalTextBlock--0HoN2";
export var iconwrapper = "InputFile-module--iconwrapper--baM1n";
export var infoText = "InputFile-module--infoText--yKFI8";
export var inputWrapper = "InputFile-module--inputWrapper--gfK14";
export var inputfile = "InputFile-module--inputfile--8Q9Z1";
export var placeholder = "InputFile-module--placeholder--ScXEL";