// extracted by mini-css-extract-plugin
export var a = "position-module--a--DNUJF";
export var absolute = "position-module--absolute--QzMA-";
export var anchorButton = "position-module--anchorButton--qtY5O";
export var anchorButtonContent = "position-module--anchorButtonContent--33Ns5";
export var applyInfoText = "position-module--applyInfoText--vVKz+";
export var body = "position-module--body--f9Hm2";
export var fixed = "position-module--fixed--zwpY1";
export var iconWrapper = "position-module--iconWrapper--fHl01";
export var image = "position-module--image--IuAMP";
export var location = "position-module--location--KdHXT";
export var mount = "position-module--mount--RuJPz";
export var socialButton = "position-module--socialButton--7-eFv";
export var socialMediaButton = "position-module--socialMediaButton--BP8CD";
export var socialSharingBlockLarge = "position-module--socialSharingBlockLarge--N8Nd+";
export var socialSharingBlockSmall = "position-module--socialSharingBlockSmall--xITmy";
export var specialButton = "position-module--specialButton--tYvSQ";
export var subtitle = "position-module--subtitle--Vh5yq";
export var text = "position-module--text--g7BCV";
export var title = "position-module--title--vyzFq";
export var unmount = "position-module--unmount--2UD0H";
export var wysiwygWrapper = "position-module--wysiwygWrapper--wmNzA";