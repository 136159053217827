import * as React from 'react';
import * as s from './InputFile.module.scss';
import Button from '../../swace/button';
import upload from '../../../img/cloud-upload.svg';
import closeSvg from '../../../img/close.svg';
import Text from '../Text';

interface Props {
  onChange: Function;
  file: File;
  fullWidth?: boolean;
  title: string;
  inputId: string;
  optional?: boolean;
}

class InputFile extends React.Component<Props> {
  state = { currentFile: null };

  private ref = React.createRef<any>();

  onChange = (e) => {
    const { onChange } = this.props;
    const files = e.target.files;
    onChange(files);
    this.setState({ currentFile: files.length ? files[0] : null });
  };

  render() {
    const { currentFile } = this.state;
    const { fullWidth, title, inputId, optional } = this.props;
    const inputFileClasses = [s.inputWrapper];
    currentFile && inputFileClasses.push(s.fileAdded);
    return (
      <>
        <div
          className={`col-12 ${fullWidth ? 'col-md-12' : 'col-md-6'} ${
            s.buttonwrapper
          }`}
        >
          <div className={s.horizontalTextBlock}>
            <Text h6>{title}</Text>
            {optional && <div className={s.infoText}>optional</div>}
          </div>
          <Button
            type="button"
            filled
            notFixedWidth
            onClick={(e) => {
              e.preventDefault();
              if (this.ref) this.ref.current.click();
            }}
          >
            <div className={s.iconwrapper}>
              Add file
              <img src={upload} />
            </div>
          </Button>
          <div className={inputFileClasses.join(' ')}>
            <div className={s.inputfile}>
              <Text pSmall>{currentFile ? currentFile.name : ''}</Text>
            </div>
            <input
              type="file"
              id={inputId || 'file'}
              ref={this.ref}
              onChange={this.onChange}
              hidden
              accept="application/pdf"
            />
            <img
              src={closeSvg}
              alt=""
              className={s.closeIcon}
              onClick={() => {
                this.setState({ currentFile: null });
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default InputFile;
